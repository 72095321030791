import { ref, watch, computed } from '@vue/composition-api'
import router from '@/router'
import store from '@/store'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default function useMaterialsList() {
  // Use toast
  const toast = useToast()

  const refMaterialListTable = ref(null)

  const currentQuery = router.currentRoute.query

  // Table Handlers
  const tableColumns = [
    { key: 'image', sortable: false },
    { key: 'category_type', sortable: false },
    { key: 'description', sortable: false },
    { key: 'style', sortable: false },
    { key: 'id', sortable: true, label: 'Created at' },
  ]
  const perPage = ref(+currentQuery.perPage || 10)
  const totalMaterials = ref(0)
  const currentPage = ref(+currentQuery.currentPage || 1)
  const perPageOptions = [10, 25, 50, 100]
  const searchQuery = ref(currentQuery.searchQuery || '')
  const sortBy = ref(currentQuery.sortBy || 'id')
  const isSortDirDesc = ref(currentQuery.isSortDirDesc === 'true' || false)
  const categoryTypeFilter = ref(currentQuery.categoryTypeFilter || null)

  const dataMeta = computed(() => {
    const localItemsCount = refMaterialListTable.value ? refMaterialListTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalMaterials.value,
    }
  })

  const refetchData = () => {
    const query = {}
    if (currentPage.value) {
      query.currentPage = currentPage.value
    }
    if (perPage.value) {
      query.perPage = perPage.value
    }
    if (searchQuery.value) {
      query.searchQuery = searchQuery.value
    }
    if (categoryTypeFilter.value) {
      query.categoryTypeFilter = categoryTypeFilter.value
    }
    if (sortBy.value) {
      query.sortBy = sortBy.value
    }
    query.isSortDirDesc = isSortDirDesc.value ? 'true' : 'false'
    router.push({ name: router.currentRouteName, query })
    refMaterialListTable.value.refresh()
  }

  watch([currentPage, perPage], () => {
    refetchData()
  })

  watch([searchQuery, categoryTypeFilter], () => {
    if (currentPage.value !== 1) {
      currentPage.value = 1
    } else {
      refetchData()
    }
  })

  const fetchMaterials = (ctx, callback) => {
    const ordering = isSortDirDesc.value ? sortBy.value : `-${sortBy.value}`
    store
      .dispatch('app-material/fetchMaterials', {
        q: searchQuery.value,
        perPage: perPage.value,
        page: currentPage.value,
        ordering,
        category_type: categoryTypeFilter.value,
      })
      .then(response => {
        const materials = response.data.results
        callback(materials)
        totalMaterials.value = response.data.count
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error fetching materials list',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  return {
    fetchMaterials,
    tableColumns,
    perPage,
    currentPage,
    totalMaterials,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refMaterialListTable,
    refetchData,

    categoryTypeFilter,
  }
}
